import { useState, useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { isMobile } from 'react-device-detect'
import {
  FALLBACK_PROVIDER_NAME,
  IS_IN_IFRAME,
  SupportedChain,
} from '../lib/constants'
import { metaMask as injected } from '../lib/connectors/metamask'
import { network } from '../lib/connectors/network'
import { gnosisSafe } from '../lib/connectors/gnosisSafe'
import { walletConnectV2 } from '../lib/connectors/walletconnectv2'
import getWeb3L1 from '../js/libs/getWeb3L1'

async function connect(connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate()
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`)
  }
}

const connectEagerly = async () => {
  await connect(network)
  await connect(gnosisSafe)
  await connect(walletConnectV2)
}

export function useActiveWeb3React() {
  const context = useWeb3React()
  // const contextFallback = useWeb3React(FALLBACK_PROVIDER_NAME)

  // return context.active ? context : contextFallback
  return context
}

export const useEagerConnect = () => {
  useEffect(() => {
    connectEagerly()
  }, [])
}

export const useInactiveListener = (suppress = false) => {
  const { active, error, activate } = useWeb3React()

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        injected.activate().catch((error) => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          // eat errors
          injected.activate().catch((error) => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}

export const useWeb3ForChainId = (chainId) => {
  return useMemo(() => {
    if (!chainId) return null
    if (chainId === SupportedChain.Ethereum) return getWeb3L1(chainId)
    return getWeb3L1(SupportedChain.Ethereum)
  }, [chainId])
}
