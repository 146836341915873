import { useContext, useMemo } from 'react'
import { useGridNFTContract, useMintManagerContract } from './use-contract'
import processPaintedEvent from '../lib/actions/process-painted-event'
import ERC1155 from '../contracts/common/ERC1155.json'
import { useEthersSigner } from './use-ethers-signer'
import { ethers } from 'ethers'
import { getJsonFromEndpoint } from '../lib/utils'
import { EthersL1Context } from '../main'

const BLOCK_NUMBER_EARLIEST = 15688129

export const useGridNFTDataSource = () => {
  const contract = useGridNFTContract()
  const archiveContract = useGridNFTContract(true)
  const ethersProviderL1 = useContext(EthersL1Context)
  const mintManagerContract = useMintManagerContract()
  const signer = useEthersSigner()

  return useMemo(() => {
    const getPaintedEventsForTokenId = (
      tokenId,
      fromBlock = BLOCK_NUMBER_EARLIEST,
      toBlock = 'latest'
    ) => {
      const paintedFilter = archiveContract.filters.Painted(
        ethers.BigNumber.from(tokenId),
        null,
        null
      )

      const fromBlockHex =
        fromBlock === 0
          ? ethers.utils.hexValue(BLOCK_NUMBER_EARLIEST)
          : ethers.utils.hexValue(fromBlock)
      const toBlockHex =
        toBlock === 'latest' ? 'latest' : ethers.utils.hexValue(toBlock)

      console.log(
        'checking painted events for tokenId',
        tokenId,
        'fromBlock',
        fromBlockHex,
        'toBlock',
        toBlockHex
      )
      return archiveContract.queryFilter(
        paintedFilter,
        fromBlockHex,
        toBlockHex
      )
    }

    const getPaintedEventsForTokenIds = async (
      tokenIds,
      fromBlock = BLOCK_NUMBER_EARLIEST,
      toBlock = 'latest'
    ) => {
      let events = []
      for (const tokenId of tokenIds) {
        const tokenEvents = await getPaintedEventsForTokenId(
          tokenId,
          fromBlock,
          toBlock
        )
        events.push(...tokenEvents)
      }
      return events
    }

    const getCurrentGridContentsBlock = (tokenId) => {
      return contract?.getCurrentGridContentsBlock(tokenId)
    }
    const getCurrentMintMode = () => {
      return mintManagerContract?.mintMode()
    }

    return {
      checkCanMintPublic: async () => {
        const currentMintMode = await getCurrentMintMode()
        return parseInt(currentMintMode) === 2
      },

      mintGridNft: async (amountToMint) => {
        const publicSalePrice = await mintManagerContract?.mintPricePublic()

        const mintCost = ethers.BigNumber.from(publicSalePrice).mul(
          ethers.BigNumber.from(amountToMint)
        )
        return contract
          ?.connect(signer)
          .mint(amountToMint, { value: mintCost })
          .wait()
      },
      setPixelsOnGrid: async (
        tokenId,
        colorIndex,
        pixelGroups,
        senderAddress
      ) => {
        try {
          // Send transaction
          const tx = await contract
            ?.connect(signer)
            .setPixels(ethers.BigNumber.from(tokenId), colorIndex, pixelGroups)

          // Wait for the transaction to be mined
          const receipt = await tx.wait()

          // Check if the Painted event is emitted
          const paintedEvent = receipt.events?.filter(
            (event) => event.event === 'Painted'
          )

          if (paintedEvent.length > 0) {
            // If we have the Painted event, resolve with the event details
            return paintedEvent[0] // Adjust this as necessary based on your needs
          } else {
            throw new Error(
              'Transaction successful but Painted event not found'
            )
          }
        } catch (error) {
          console.error(error)
          throw error // Rethrow the error to be caught by the caller
        }
      },

      getCurrentGridContents: async (tokenId) => {
        let blockNumber
        try {
          blockNumber = await getCurrentGridContentsBlock(
            ethers.BigNumber.from(tokenId)
          )
          if (ethers.BigNumber.from(blockNumber).eq(ethers.BigNumber.from(0))) {
            blockNumber = BLOCK_NUMBER_EARLIEST
          }
        } catch (e) {
          console.log(e)
          blockNumber = BLOCK_NUMBER_EARLIEST
        }

        const events = await getPaintedEventsForTokenId(
          tokenId,
          blockNumber,
          blockNumber !== BLOCK_NUMBER_EARLIEST ? blockNumber : 'latest'
        )

        if (!events || events.length === 0) {
          throw new Error('No events found for tokenId ' + tokenId)
        }

        let event = events[0]
        if (events.length > 1) {
          for (let i = 1; i < events.length; i++) {
            if (events[i].blockNumber > event.blockNumber) {
              event = events[i]
            }
          }
        }

        return processPaintedEvent(event)
      },

      getCurrentGridContentsBlock,
      getPaintedEventsForTokenId,
      getPaintedEventsForTokenIds,
      getTotalGridContentsIterations: (tokenId) => {
        return contract?.getTotalGridContentsIterations(tokenId)
      },
      getPaintTokenCost: () => {
        return contract?.PRICE_PER_PIXEL()
      },
      getGridWidth: () => {
        return contract?.GRID_WIDTH()
      },
      getCoverageCost: () => {
        return contract?.COVERAGE_COST()
      },
      calculatePaintTokenCostForPixels: async (pixels) => {
        const costPerPixel = await contract?.PRICE_PER_PIXEL()
        return ethers.BigNumber.from(pixels).mul(
          ethers.BigNumber.from(costPerPixel)
        )
      },
      getBalanceForAddress: (address) => {
        return contract?.balanceOf(address)
      },
      ownerOf: (tokenId) => {
        return contract?.ownerOf(tokenId)
      },
      name: () => {
        return contract?.name()
      },
      symbol: () => {
        return contract?.symbol()
      },
      totalSupply: () => {
        return contract?.totalSupply()
      },
      getOwnedTokenIdsInIndexRange: (ownerAddress, startIndex, endIndex) => {
        const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
          startIndex,
          endIndex + 1
        )

        return Promise.all(
          indexes.map((index) =>
            contract?.tokenOfOwnerByIndex(ownerAddress, index)
          )
        )
      },
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return contract?.tokenOfOwnerByIndex(ownerAddress, index)
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        return contract
          ?.connect(signer)
          .transferFrom(
            senderAddress,
            recipientAddress,
            ethers.BigNumber.from(tokenId)
          )
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        return contract
          ?.connect(signer)
          .setApprovalForAll(spenderAddress, approved)
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return contract?.isApprovedForAll(ownerAddress, spenderAddress)
      },
      getTokenUri: (tokenId) => {
        return contract?.tokenURI(tokenId)
      },
      isTokenInFreeForAllMode: (tokenId) => {
        return contract?.freeForAllMode(tokenId)
      },
      setFreeForAllModeForToken: (tokenId, freeForAll, ownerAddress) => {
        return contract
          ?.connect(signer)
          .setFreeForAllForToken(tokenId, freeForAll)
      },
      ownsFreeForAllAllowToken: async (address) => {
        try {
          const erc1155TokenContractAddress =
            await contract?.allowTokenAddress()
          const erc1155TokenId = await contract?.allowTokenId()

          // Assuming you have an ethers provider instance
          const erc1155Contract = new ethers.Contract(
            erc1155TokenContractAddress,
            ERC1155.abi,
            ethersProviderL1
          )

          // Fetch balance
          const balance = await erc1155Contract.balanceOf(
            address,
            erc1155TokenId
          )

          // Using ethers.BigNumber methods to compare
          return balance.gt(ethers.constants.Zero)
        } catch (error) {
          console.error(error)
          throw error // Rethrow or handle as needed
        }
      },
      getOffchainRemoteData: async (tokenId) => {
        let tokenUri = await contract?.tokenURI(ethers.BigNumber.from(tokenId))

        let returnData = await getJsonFromEndpoint(tokenUri)

        return returnData
      },
      getFullTokenUriMetadata: async (tokenId) => {
        let tokenUri = await contract?.tokenURI(ethers.BigNumber.from(tokenId))

        let returnData = await getJsonFromEndpoint(tokenUri)

        return returnData
      },
    }
  }, [contract, signer, mintManagerContract, ethersProviderL1])
}
