import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import CloseIconButton from '../buttons/close'
import headerLogo from '../../../../images/logos/murall/text-right.svg'
import makeStyles from '@mui/styles/makeStyles'

const TransparentDialog = ({
  disableEscapeKeyDown,
  fullScreen,
  onClose,
  open,
  scroll,
  title,
  showLogo,
  showCloseButton,
  dialogContent,
  footerContent,
  hideFooter,
  onOk,
  okTitle,
  onCancel,
  cancelTitle,
} = {}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      fullWidth={true}
      scroll={scroll}
      maxWidth={'md'}
      PaperProps={{
        style: {
          borderRadius: 10,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          backgroundImage: 'none',
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(83, 6, 152, 0.2)',
          '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
            {
              backgroundColor: 'rgba(0,0,30,0.85)',
            },
        },
      }}
    >
      {showLogo && (
        <LogoWrapper>
          <img src={headerLogo} alt="MurAll logo and title" />
        </LogoWrapper>
      )}
      <TitleRow>
        <Typography component="h3" variant="h4" noWrap>
          {title}
        </Typography>
        {showCloseButton && (
          <CloseButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          />
        )}
      </TitleRow>

      <DialogContent>{dialogContent}</DialogContent>
      {!hideFooter && (
        <Footer isMobile={isMobile}>
          {footerContent}
          <FooterButton title={okTitle} onClick={onOk} variant="contained" />
          <FooterButton
            title={cancelTitle}
            onClick={onCancel}
            variant="outlined"
          />
        </Footer>
      )}
    </Dialog>
  )
}

const TitleRow = styled(DialogTitle)`
  display: flex;
`

const CloseButton = styled(CloseIconButton)`
  margin-left: auto;
`

const LogoWrapper = styled.div`
  max-height: 50px;
  max-width: 200px;
  margin: 0 0 ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2)};
`

const Footer = styled(DialogActions)`
  margin: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)}
    0 ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

const FooterButton = ({ title, onClick, variant }) => {
  return (
    <Button size="large" color="primary" onClick={onClick} variant={variant}>
      {title}
    </Button>
  )
}

export default TransparentDialog
