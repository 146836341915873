import { SupportedChain } from '../constants'

export const MAINNET_CHAINS = {
  [SupportedChain.Ethereum]: {
    urls: [
      'https://eth-mainnet.g.alchemy.com/v2/JBcsj6b99HP27jZ-QRJ5ekt1PDVXicHS',
      'https://ethereum.publicnode.com',
      'https://rpc.ankr.com/eth',
    ].filter(Boolean),
    name: 'Mainnet',
  },
}

export const TESTNET_CHAINS = {
  [SupportedChain.Rinkeby]: {
    urls: [
      'https://eth-rinkeby.alchemyapi.io/v2/puDsFO1WUH-LWm51l3K94J4PQTEZve9H',
    ].filter(Boolean),
    name: 'Rinkeby',
  },
}

export const CHAINS = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

export const URLS = Object.keys(CHAINS).reduce((accumulator, chainId) => {
  const validURLs = CHAINS[Number(chainId)].urls

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs
  }

  return accumulator
}, {})
