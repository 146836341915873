import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import EditIcon from '@mui/icons-material/Edit'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import ImagePreviewDialog from '../../../js/uicomponents/image_preview_dialog'
import { useActiveWeb3React } from '../../../hooks/web3'
import { useGridNFTDataSource } from '../../../hooks/use-grid-nft-datasource'
import LoadingButton from '@mui/lab/LoadingButton'
import { useHistory } from 'react-router-dom'
import { Icon, Button } from '@mui/material'
import { getGridNFTContractAddress } from '../../../js/modules/blockchain/datasource/ContractAddressDataSource'
import { SupportedChain } from '../../../lib/constants'
import notification from '../../../lib/notification'

export default function GridNftPreviewDialog(props) {
  const history = useHistory()
  const { account, chainId } = useActiveWeb3React()
  const {
    ownerOf,
    isTokenInFreeForAllMode,
    setFreeForAllModeForToken,
    ownsFreeForAllAllowToken,
  } = useGridNFTDataSource()
  const [isOwner, setOwner] = useState(null)
  const [isFreeForAll, setFreeForAll] = useState(null)
  const [canDrawFreeForAll, setCanDrawFreeForAll] = useState(null)
  const [loading, setLoading] = useState(false)

  const { image, tokenId } = props
  useEffect(() => {
    if (!account) return

    const checkStatus = async () => {
      if (account) {
        setLoading(true)
        const owner = await ownerOf(tokenId)

        if (owner === account) {
          setOwner(owner)
        } else {
          setOwner(false)
        }

        const ownsAllowToken = await ownsFreeForAllAllowToken(account)

        setCanDrawFreeForAll(ownsAllowToken)
        setCanDrawFreeForAll(true)

        const freeForAll = await isTokenInFreeForAllMode(tokenId)
        setFreeForAll(freeForAll)

        setLoading(false)
      }
    }
    checkStatus()
  }, [tokenId, account])

  return (
    <ImagePreviewDialog
      {...props}
      image={image}
      alt={`grid #${tokenId}`}
      dialogFooter={() => {
        return (
          <>
            <Button
              size="large"
              variant="contained"
              align="center"
              onClick={() => {
                window.open(
                  `https://${
                    chainId !== SupportedChain.Ethereum ? 'testnets.' : ''
                  }opensea.io/assets/${
                    chainId === SupportedChain.Ethereum ? 'ethereum' : 'goerli'
                  }/${getGridNFTContractAddress(chainId)}/${tokenId}`,
                  '_blank'
                )
              }}
              endIcon={
                <Icon style={{ fontSize: 28, textAlign: 'center' }}>
                  <img src={'images/opensea_logo_white.svg'} alt={'opensea'} />
                </Icon>
              }
              sx={{
                backgroundColor: '#2081e2',
              }}
            >
              View on OpenSea
            </Button>
            <LoadingButton
              size="large"
              loading={loading}
              disabled={
                loading || !isOwner
                  ? !(isFreeForAll && canDrawFreeForAll)
                  : !isOwner
              }
              loadingPosition="end"
              endIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                history.push(`/draw/${tokenId}`)
              }}
            >
              {loading ? 'Checking status...' : 'Edit'}
            </LoadingButton>
            {isOwner && (
              <LoadingButton
                loading={loading}
                disabled={loading}
                size="large"
                variant="contained"
                align="center"
                onClick={() => {
                  notification.promise(
                    setFreeForAllModeForToken(tokenId, !isFreeForAll, account),
                    {
                      loading: `${
                        isFreeForAll ? 'Disabling' : 'Enabling'
                      } Free For All on #${tokenId}`,
                      success: `Free For All ${
                        isFreeForAll ? 'Disabled' : 'Enabled'
                      } for #${tokenId}!`,
                      error: (error) => {
                        return `Failed to ${
                          isFreeForAll ? 'Disable' : 'Enable'
                        } Free For All for Grid #${tokenId}: ${error}`
                      },
                    }
                  )
                }}
                endIcon={isFreeForAll ? <LockIcon /> : <LockOpenIcon />}
              >
                {loading
                  ? 'Please wait...'
                  : (isFreeForAll ? 'Disable' : 'Enable') + ' Free For All'}
              </LoadingButton>
            )}
          </>
        )
      }}
    />
  )
}
