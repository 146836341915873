import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
  WalletConnect as WalletConnectConnector,
  URI_AVAILABLE
} from '@web3-react/walletconnect-v2'
import {
  walletConnectV2,
  hooks as walletConnectV2Hooks
} from '../../../../lib/connectors/walletconnectv2'
import Divider from '@mui/material/Divider'
import Header from './header'
import PendingView from './pending'
import ErrorContent from './error'
import WalletList from './list'
import Account from './account'
import { switchToEthereum } from '../../../../lib/actions/switch-network-to-ethereum'
import { SupportedChain } from '../../../../lib/constants'
import notification from '../../../../lib/notification'
export const VIEWS = {
  LIST: 'list',
  ACCOUNT: 'account',
  PENDING: 'pending'
}

const WalletModalContent = ({ isModalOpen, closeModal }) => {
  const { account, activate, error, chainId } = useWeb3React()

  const [view, setView] = useState(VIEWS.ACCOUNT)
  const [pendingWallet, setPendingWallet] = useState()
  const [pendingError, setPendingError] = useState()

  const [uri, setUri] = useState()
  useEffect(() => {
    const handleUriAvailable = uri => {
      setUri(uri)
      closeModal()
      setPendingError(false)
      setView(VIEWS.LIST)
    }

    // Subscribe to the URI_AVAILABLE event
    walletConnectV2.events.on(URI_AVAILABLE, handleUriAvailable)

    // Cleanup function to unsubscribe from the event
    return () => {
      walletConnectV2.events.off(URI_AVAILABLE, handleUriAvailable)
    }
  }, [])

  // always reset to account view
  useEffect(() => {
    if (isModalOpen) {
      setPendingError(false)
      setView(VIEWS.ACCOUNT)
    }
  }, [isModalOpen])

  const tryActivation = async connector => {
    setPendingWallet(connector)
    setView(VIEWS.PENDING)

    connector &&
      connector.activate().catch(error => {
        console.error('Failed to activate connector', error)
        if ( error &&
          error.message &&
          error.message.indexOf('chains are not supported') != -1) {
          notification.error('Ethereum is not supported on this wallet')
        }
        if (account && chainId !== SupportedChain.Ethereum) {
          switchToNetwork({ connector, chainId: SupportedChain.Ethereum })
          connector.activate() // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true)
        }
      })
  }

  const getBody = () => {
    if (error) return <ErrorContent />

    if (account && view === VIEWS.ACCOUNT)
      return <Account onChangeClick={() => setView(VIEWS.LIST)} />

    return view === VIEWS.PENDING ? (
      <PendingView
        connector={pendingWallet}
        error={pendingError}
        setPendingError={setPendingError}
        tryActivation={tryActivation}
      />
    ) : (
      <WalletList setView={setView} activate={tryActivation} />
    )
  }

  return (
    <>
      <Header
        onClose={closeModal}
        showBackButton={!error && view !== VIEWS.ACCOUNT}
        onBackClick={() => {
          setPendingError(false)
          setView(VIEWS.ACCOUNT)
        }}
      />
      <Divider />
      <Body>{getBody()}</Body>
    </>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export default WalletModalContent
