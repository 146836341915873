import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../lib/constants'
import Worker from '../../workers/blockchain/parseGridNftDataToCanvas.worker'
import { ethers } from 'ethers'

const emptyHex4 = '0000'

const WHITE_IMAGE_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAAetJREFUeF7t2MENACAMw8Cy/7rwBok1fGxQ7EeSdfa542V/YBEgy/4fToA2fwLE+ROAAEJg2gEZII1fCIzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOP6ZB4Fq7xB1dURmAAAAAElFTkSuQmCC'
export const processGridNftData = async (
  contractAddress,
  tokenId,
  iteration,
  colorIndexData,
  pixelGroups
) => {
  const offScreenCanvas = createOffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT)
  const ctx = offScreenCanvas.getContext('2d')
  disableCanvasSmooth(ctx)
  let image
  if (pixelGroups.length === 0) {
    image = WHITE_IMAGE_DATA_URL
  } else {
    const colorIndex = processColorIndexData(colorIndexData)
    const imageData = ctx.getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
    const buf = await drawNftDataToCanvasBuffer(
      imageData.data.buffer,
      colorIndex,
      pixelGroups,
      true
    )

    const arr = new Uint8ClampedArray(buf)
    let processedImageData
    try {
      processedImageData = new ImageData(arr, imageData.width, imageData.height)
    } catch (e) {
      processedImageData = ctx.createImageData(
        imageData.width,
        imageData.height
      )
      processedImageData.data.set(arr)
    }

    ctx.putImageData(processedImageData, 0, 0)
    image = offScreenCanvas.toDataURL()
  }

  return {
    iteration,
    contractAddress,
    tokenId,
    colorIndexData,
    pixelGroups,
    image,
  }
}

export const drawNftDataToCanvasBuffer = (
  canvasBuffer,
  colorIndexData,
  pixelGroups,
  colorIndexPreprocessed = false
) => {
  const encoder = new TextEncoder()
  const isDeviceLittleEndian = isLittleEndian()
  return new Promise(function (resolve, reject) {
    const encodedColorIndex = encoder.encode(JSON.stringify(colorIndexData))

    const encodedPixelGroups = encoder.encode(JSON.stringify(pixelGroups))

    const worker = new Worker()

    worker.onmessage = function (msg) {
      worker.terminate()
      resolve(msg.data.canvasBuffer)
    }

    const buffers = [
      canvasBuffer,
      encodedColorIndex.buffer,
      encodedPixelGroups.buffer,
    ]
    const message = {
      canvasBuffer,
      encodedColorIndex,
      encodedPixelGroups,
      isLittleEndian: isDeviceLittleEndian,
      buffers,
      colorIndexPreprocessed,
    }

    worker.postMessage(message, buffers)
  })
}

const processColorIndexData = (colorIndexes, rgbaFormat = false) => {
  let currentColourGroup
  const colourList = []

  for (var i = 0, n = colorIndexes.length; i < n; i++) {
    currentColourGroup = leftPad(
      ethers.BigNumber.from(colorIndexes[i]).toHexString(),
      64
    )

    if (i === colorIndexes.length - 1) {
      // we're at the last index, find any empty slots and remove them too
      while (currentColourGroup.slice(0, 4) == emptyHex4) {
        currentColourGroup = currentColourGroup.slice(4)
      }
    }
    for (var j = 0, k = currentColourGroup.length / 4; j < k; j++) {
      const colour = currentColourGroup.slice(4 * j, 4 * (j + 1))
      if (rgbaFormat) {
        colour = rgb565HexToRgba('0x'.concat(colour))
      }
      colourList.push(colour)
    }
  }

  return colourList
}

export const createOffscreenCanvas = (width, height) => {
  let offScreenCanvas = document.createElement('canvas')
  offScreenCanvas.width = width
  offScreenCanvas.height = height

  return offScreenCanvas //return canvas element
}

/**
 * Runs a test to check if the browser is little or big endian by altering a colour value and seeing if the values are reversed or not
 * @return True if little endian
 */
export const isLittleEndian = () => {
  let isLittleEndian = true
  let buf = new ArrayBuffer(4)
  let buf8 = new Uint8ClampedArray(buf)
  let data = new Uint32Array(buf)
  data[0] = 0x0f000000
  if (buf8[0] === 0x0f) {
    isLittleEndian = false
  }

  return isLittleEndian
}

export const rgb565HexToRgba = (rgb565Hex) => {
  let rgb565Int = parseInt(rgb565Hex)
  let r = (((rgb565Int >> 11) & 0x1f) * 527 + 23) >> 6
  let g = (((rgb565Int >> 5) & 0x3f) * 259 + 33) >> 6
  let b = ((rgb565Int & 0x1f) * 527 + 23) >> 6

  let RGB888 = (r << 16) | (g << 8) | b
  let RGB = hexToBytes6(RGB888.toString(16))
  let RGBAHex = '0x'.concat(RGB).concat('FF')

  return parseInt(RGBAHex)
}

/**
 * removes smoothing from canvas
 *
 * @param {ctx} ctx
 */
export const disableCanvasSmooth = (ctx) => {
  ctx.webkitImageSmoothingEnabled = false
  ctx.oImageSmoothingEnabled = false
  ctx.msImageSmoothingEnabled = false
  ctx.imageSmoothingEnabled = false
}

// left pads and removes the 0x from the start
const leftPad = (string, chars, sign) => {
  string = string.toString(16).replace(/^0x/i, '')
  var padding = chars - string.length + 1 >= 0 ? chars - string.length + 1 : 0
  return new Array(padding).join(sign ? sign : '0') + string
}
