import { useMemo } from 'react'
import { ethers } from 'ethers'
import { useEthersProviderForChainId } from './use-ethers-provider-for-chain-id'
import { SupportedChain } from '../lib/constants'

// returns null on errors
export const useEthersContractForAddress = ({
  abi,
  contractAddress,
  targetChainId = SupportedChain.Ethereum,
  forceArchiveNode = false,
  websocketInstance = false
}) => {
  const ethersProvider = useEthersProviderForChainId(
    targetChainId,
    forceArchiveNode,
    websocketInstance
  )

  return useMemo(() => {
    if (!ethersProvider) {
      console.error('Failed to get provider')
      return null
    }
    if (!abi) {
      console.error(
        'Failed to get contract for chainId '+ targetChainId + ' - abi is null',
        contractAddress
      )
      return null
    }
    if (!contractAddress) {
      console.error(
        'Failed to get contract - contractAddress is null',
        targetChainId
      )
      return null
    }

    try {
      return new ethers.Contract(contractAddress, abi, ethersProvider)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [contractAddress, ethersProvider, targetChainId, abi])
}
