import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import MurallSprite from '../../js/uicomponents/loading_spinner'
import { css, Theme } from '@emotion/react'

const backdropStyles = (theme) => css`
  backdrop-filter: blur(4px);
  background-color: rgba(83, 6, 152, 0.2);
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(0, 0, 30, 0.85);
  }
  z-index: ${theme.zIndex.drawer + 1};
`

const Loader = ({ show = true, useLogo, full }) => {
  const component = useLogo ? <MurallSprite /> : <CircularProgress />

  return (
    <Backdrop
      sx={{ zIndex: (theme) => (full ? theme.zIndex.drawer + 1 : null) }}
      open={show}
      css={(theme) => backdropStyles(theme)}
    >
      {component}
    </Backdrop>
  )
}

export default Loader
