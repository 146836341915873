import { useMemo } from 'react'
import { getGridNFTContractAddress } from '../../js/modules/blockchain/datasource/ContractAddressDataSource.js'
import notification from '../../lib/notification.js'
import { useGridNFTDataSource } from '../use-grid-nft-datasource.js'
import { usePaintDataSource } from '../use-paint-datasource.js'
import { useActiveWeb3React } from '../web3.js'

export const useSetPixelsOnGrid = () => {
  const { account, chainId } = useActiveWeb3React()
  const {
    isTokenTransferApprovedForAmount,
    approveTokenTransfer,
    maxAllowance,
  } = usePaintDataSource()
  const { setPixelsOnGrid } = useGridNFTDataSource()

  return useMemo(() => {
    return {
      setPixelsOnGridAndNotify: async (detail, tokenId) => {
        const allowed = await isTokenTransferApprovedForAmount(
          account,
          getGridNFTContractAddress(chainId),
          detail.costInPaint
        )

        if (!allowed) {
          const funcPromise = approveTokenTransfer(
            account,
            getGridNFTContractAddress(chainId),
            maxAllowance()
          )

          try {
            await notification.promise(funcPromise, {
              loading:
                'The Grid needs permission to spend your Paint to continue',
              success: 'You can now draw on The Grid!',
              error: (error) => {
                if (
                  error &&
                  error.message &&
                  error.message.indexOf('User denied') != -1
                )
                  return 'You rejected the transaction!'
                return `Sorry, the transaction failed: ${
                  error ? error.name : error
                }`
              },
            })
          } catch (error) {
            console.error(error)
            throw error
          }
        }

        const setPixelsPromise = setPixelsOnGrid(
          tokenId,
          detail.colourIndexData,
          detail.blockchainPixelGroupData
        )

        try {
          const returnData = await notification.promise(setPixelsPromise, {
            loading: `Drawing to Grid #${tokenId}...`,
            success: (event) => `Successfully updated Grid #${tokenId}!`,
            error: (error) => {
              if (error.message && error.message.indexOf('User denied') != -1) {
                return 'You rejected the transaction!'
              }
              return `Sorry, the transaction failed: ${error.name}`
            },
          })
          return returnData
        } catch (error) {
          console.error(error)
          throw error
        }
      },
    }
  }, [
    chainId,
    isTokenTransferApprovedForAmount,
    approveTokenTransfer,
    maxAllowance,
    account,
    getGridNFTContractAddress,
    setPixelsOnGrid,
  ])
}

export default useSetPixelsOnGrid
