import { useMemo } from 'react'
import { ethers } from 'ethers'
import IERC20 from '../contracts/common/ERC20.json'
import { MAX_UINT, SupportedChain } from '../lib/constants'
import { isTransactionSuccess } from '../js/libs/appUtils'
import { useEthersContractForAddress } from './use-ethers-contract'
import { useEthersSigner } from './use-ethers-signer'
export const useErc20Contract = (contractAddress, targetChainId) => {
  return useEthersContractForAddress({
    abi: IERC20.abi,
    contractAddress: contractAddress,
    targetChainId: targetChainId,
  })
}

export const useErc20DataSource = (erc20TokenContractAddress) => {
  const contract = useErc20Contract(
    erc20TokenContractAddress,
    SupportedChain.Ethereum
  )
  const signer = useEthersSigner()

  return useMemo(() => {
    return {
      getBalanceForAddress: (address) => {
        return contract?.balanceOf(address)
      },
      getTotalSupply: () => {
        return contract?.totalSupply()
      },
      name: () => {
        return contract?.name()
      },
      symbol: () => {
        return contract?.symbol()
      },
      decimals: () => {
        return contract?.decimals()
      },
      getTokenInformation: async () => {
        return Promise.all([
          contract?.name(),
          contract?.symbol(),
          contract?.decimals(),
        ]).then(function (values) {
          return {
            contractAddress: contractAddress,
            name: values[0],
            symbol: values[1],
            decimals: values[2],
          }
        })
      },
      isTokenTransferApprovedForAmount: async (
        senderAddress,
        spenderAddress,
        amount
      ) => {
        var allowance = await contract?.allowance(senderAddress, spenderAddress)
        console.log('allowance', allowance)

        return ethers.BigNumber.from(allowance).gte(
          ethers.BigNumber.from(amount)
        )
      },
      transferAmountToAddress: (senderAddress, recipientAddress, amount) => {
        return contract?.connect(signer).transfer(recipientAddress, amount)
      },
      approveTokenTransfer: async (senderAddress, spenderAddress, amount) => {
        try {
          const contractWithSigner = contract?.connect(signer)
          const response = await contractWithSigner.approve(
            spenderAddress,
            amount
          )
          const receipt = await response.wait()

          if (isTransactionSuccess(receipt)) {
            return true
          } else {
            throw new Error('Transaction Failed')
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      },
      maxAllowance: () => {
        return ethers.BigNumber.from(MAX_UINT)
      },
    }
  }, [contract, signer])
}
