import React, { useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'

import ConnectWalletModal from './connect-modal'
import AccountAddress from './address'
import { switchToEthereum } from '../../../lib/actions/switch-network-to-ethereum'
import { SupportedChain } from '../../../lib/constants'
import switchToNetwork from '../../../lib/actions/switch-network'

const WalletDisplay = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Display onClick={() => setOpen(true)} />
      <ConnectWalletModal isOpen={open} closeModal={() => setOpen(false)} />
    </>
  )
}

const Display = ({ onClick }) => {
  const { account, chainId, error, connector } = useWeb3React()

  if (error || (account && chainId !== SupportedChain.Ethereum))
    return (
      <Error
        onClick={() => {
          if (chainId !== SupportedChain.Ethereum) {
            switchToNetwork({ connector, chainId: SupportedChain.Ethereum })
          }
          onClick()
        }}
      >
        {chainId !== SupportedChain.Ethereum
          ? 'Unsupported Network'
          : 'Error'}
      </Error>
    )

  if (account) {
    return (
      <ConnectedWalletDisplay onClick={onClick}>
        <AccountAddress textSize="body1" short />
      </ConnectedWalletDisplay>
    )
  }

  return (
    <ConnectWalletButton onClick={onClick}>Connect Wallet</ConnectWalletButton>
  )
}

const Button = styled.button`
  border: 0;
  outline: 0;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  margin: 0 ${({ theme }) => theme.spacing(1)};
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  width: max-content;
`

const ConnectWalletButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-image: linear-gradient(
    to right,
    transparent 0%,
    ${({ theme }) => theme.palette.secondary.main} 100%
  );
  transition: all 240ms ease-in-out;

  &:hover {
    background-image: none;
  }
`

const ConnectedWalletDisplay = styled(Button)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  transition: all 240ms ease-in-out;
`

const Error = styled(Button)`
  background-color: ${({ theme }) => theme.colors.named.red};
`

export default WalletDisplay
