import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Web3 from 'web3'
import ErrorMessageView from '../components/common/error-view'
import DrawingClient from '../js/pages/draw/drawingClient'
import { SupportedChain } from '../lib/constants'
import { useActiveWeb3React } from '../hooks/web3'
import makeStyles from '@mui/styles/makeStyles'
import DrawingTransactionDialog from '../js/pages/draw/drawingTransactionDialog'
import { ethers } from 'ethers'
import Get_transaction_data_for_layers_usecase from '../js/modules/blockchain/usecase/get_transaction_data_for_layers_usecase'
import StyledDialog from '../js/uicomponents/styled_dialog'
import Typography from '@mui/material/Typography'
import LoadingDialog from '../js/uicomponents/loading_dialog'
import _ from 'underscore'
import WelcomeDrawDialog from '../js/pages/draw/welcomeDrawDialog'
import { useUpdateTokenStateOnDrawingClient } from '../hooks/grid/use-update-token-state-on-drawing-client'
import { useParams } from 'react-router-dom'
import useSetPixelsOnGrid from '../hooks/grid/use-set-pixels-on-grid'

const useStyles = makeStyles(theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

const DrawPage = () => {
  const classes = useStyles()
  const [cookies] = useCookies(['agreedToTerms', 'hideDrawWelcome'])

  let { id } = useParams()
  useUpdateTokenStateOnDrawingClient(id)

  const network = useSelector(state => state.network)
  const terms = useSelector(state => state.terms)
  const [currentDetail, setCurrentDetail] = useState(null)
  const drawWelcome = useSelector(state => state.drawWelcome)
  const [loading, setLoading] = useState(false)
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(
    !cookies.hideDrawWelcome && !drawWelcome.hide
  )
  const [
    showTooManyColorsErrorDialog,
    setShowTooManyColorsErrorDialog
  ] = useState(false)
  const { setPixelsOnGridAndNotify } = useSetPixelsOnGrid()
  const { account, chainId } = useActiveWeb3React()
  const getTransactionDataForLayersUsecase = new Get_transaction_data_for_layers_usecase()

  const agreedToTerms = !!cookies.agreedToTerms || terms.agreed

  if (network.id && !network.supported)
    return (
      <ErrorMessageView
        title='Network unsupported'
        description='Please select the Ethereum Mainnet or Polygon network'
      />
    )

  const constructDrawTransactionInfo = (
    flatten = true,
    convertTo256Color = false
  ) => {
    setLoading(true)

    getTransactionDataForLayersUsecase
      .execute({
        flatten,
        convertTo256Color
      })
      .then(data => {
        setCurrentDetail(data)
      })
      .catch(error => {
        setCurrentDetail(null)
        setShowTooManyColorsErrorDialog(true)
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <LoadingDialog open={loading} />
      {!cookies.hideDrawWelcome && !drawWelcome.hide && (
        <WelcomeDrawDialog
          open={welcomeDialogOpen}
          onPositiveButtonClick={() => {
            setWelcomeDialogOpen(false)
          }}
          onClose={() => {
            setWelcomeDialogOpen(false)
          }}
        />
      )}
      {showTooManyColorsErrorDialog && (
        <StyledDialog
          open={showTooManyColorsErrorDialog}
          onClose={() => {
            setShowTooManyColorsErrorDialog(false)
          }}
          dialogTitle={'Error: too many colors in image'}
          dialogContent={() => (
            <Typography
              className={classes.typography}
              variant='body2'
              component='p'
            >
              Your drawing must have no more than 256 colors
              <br />
              <br />
              Would you like to reduce the colors to 256 and continue?
              <br />
              <br />
              You can manually do this by going to 'Image {'>'} Convert to 256
              color depth' or select the 256 colour palette tool on the left
              menu to convert your image to 256 colors
            </Typography>
          )}
          positiveButtonTitle={'Reduce & Continue'}
          onPositiveButtonClick={() => {
            setShowTooManyColorsErrorDialog(false)
            constructDrawTransactionInfo(true, true)
          }}
          negativeButtonTitle={'Cancel'}
          onNegativeButtonClick={() => {
            setShowTooManyColorsErrorDialog(false)
          }}
        />
      )}
      <DrawingClient
        tokenId={id}
        connected={!!account}
        supportedNetworkConnected={network.supported}
        agreedToTerms={agreedToTerms}
        isL2={network.id === SupportedChain.Polygon}
        constructDrawTransactionInfo={constructDrawTransactionInfo}
      />
      {currentDetail && (
        <DrawingTransactionDialog
          open={currentDetail}
          tokenId={id}
          numberOfPixels={currentDetail.numberOfPixels}
          blockchainPixelGroupDataLength={
            currentDetail.blockchainPixelGroupData.length
          }
          blockchainColourIndexDataLength={currentDetail.colourIndexData.length}
          costInPaint={ethers.utils.formatUnits(currentDetail.costInPaint, 'ether')}
          croppedBase64PngString={currentDetail.croppedBase64PngString}
          numberOfColors={currentDetail.numberOfColors}
          onCancelClicked={() => {
            setCurrentDetail(null)
          }}
          onDrawClicked={async () => {
            setLoading(true)
            try {
              await setPixelsOnGridAndNotify(currentDetail, id)
              setCurrentDetail(null)
            } catch (error) {
              console.error(error)
            }
            setLoading(false)
          }}
        />
      )}
    </>
  )
}

export default DrawPage
