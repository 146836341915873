import { useMemo } from 'react'
import { SupportedChain } from '../lib/constants'
import { useErc20DataSource } from './use-erc20'
import PaintContractAbiL1 from '../contracts/layer1/PaintToken.json'
import { ethers } from 'ethers'

const INITIAL_SUPPLY = ethers.BigNumber.from('22020096000000000000000000000')
// returns null on errors

export const usePaintDataSource = () => {
  const networkData = PaintContractAbiL1.networks[SupportedChain.Ethereum]
  const address = networkData?.address
  const contract = useErc20DataSource(address)

  return useMemo(() => {
    return {
      getBalanceForAddress: (address) => {
        return contract?.getBalanceForAddress(address)
      },
      getTotalSupply: () => {
        return contract?.getTotalSupply()
      },
      name: () => {
        return contract?.name()
      },
      symbol: () => {
        return contract?.symbol()
      },
      decimals: () => {
        return contract?.decimals()
      },
      getTokenInformation: () => {
        return contract?.getTokenInformation()
      },
      isTokenTransferApprovedForAmount: async (
        senderAddress,
        spenderAddress,
        amount
      ) => {
        return contract?.isTokenTransferApprovedForAmount(
          senderAddress,
          spenderAddress,
          amount
        )
      },
      transferAmountToAddress: (senderAddress, recipientAddress, amount) => {
        return contract?.transferAmountToAddress(
          senderAddress,
          recipientAddress,
          amount
        )
      },
      approveTokenTransfer: (senderAddress, spenderAddress, amount) => {
        return contract?.approveTokenTransfer(
          senderAddress,
          spenderAddress,
          amount
        )
      },
      maxAllowance: () => {
        return contract?.maxAllowance()
      },
      initialSupply: () => {
        return INITIAL_SUPPLY
      },
    }
  }, [contract])
}
