/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min
}

export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const getJsonFromEndpoint = async endpointData => {
  endpointData = replaceIpfsIfNeeded(endpointData)

  if (endpointData.indexOf('http') === -1) {
    endpointData = 'https://' + endpointData
  }

  let returnData
  if (
    endpointData &&
    endpointData.startsWith('https://data:application/json;base64,')
  ) {
    const json = Buffer.from(
      endpointData.replace('https://data:application/json;base64,', ''),
      'base64'
    )
    returnData = JSON.parse(json)
  } else if (
    endpointData &&
    endpointData.startsWith('data:application/json;base64,')
  ) {
    const json = Buffer.from(
      endpointData.replace('data:application/json;base64,', ''),
      'base64'
    )
    returnData = JSON.parse(json)
  } else {
    const response = await fetch(tokenUri)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    returnData = await response.json()
  }

  return returnData
}